// get parameters from URL (if any)
const getUTMParameters = () => {
	const params = new URLSearchParams(window.location.search); // /!\ URLSearchParams needs to be polyfilled for IE
	const utm_params = {};
	params.forEach((value, key) => {
		// console.log(`param is [${key}]=\`${value}\``);
		if (key.startsWith('utm_')) utm_params[key] = value;
		else if (key === 'mt') utm_params['mt'] = value; // also pass custom mt parameter
		else if (key === 'gclid') utm_params['gclid'] = value; // also pass custom gclid parameter
	});
	return utm_params;
};

export { getUTMParameters };